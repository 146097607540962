import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
} from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import ToastMessagesService from "../services/toastMessages.service";

export interface Message{ 
  category: 'error' | 'warning' | 'success';
  title?: string;
  message: string;
  duration?: number;
}

export interface ToastMessage extends Message{
  id:string;
}

interface MessageContextType {
  toastMessages: ToastMessage[];
  addMessage: (message:Message) => void;
  removeToastMessage: (id:string) => void;
}

const ToastMessagesContext = createContext<MessageContextType | undefined>(undefined);

export const ToastMessagesProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [toastMessages, setToastMessages] = useState<ToastMessage[]>([]);

  const addMessage = useCallback((message: Message) => {
    const id = Math.random().toString(36).substring(7);
    setToastMessages((toastMessages) =>[...toastMessages, { id, ...message }]);
  }, []); 

  const removeToastMessage = (id:string) => {
    setToastMessages(toastMessages.filter((message) => message.id !== id));
  };


  const getTitle = (toastMessage:ToastMessage) => {
    if(toastMessage.title) return toastMessage.title;

    switch (toastMessage.category) {
      case "error":
        return "Erro";
      case "success":
        return "Sucesso";
      case "warning":
        return "Atenção";
    }
  }

  const getBgColor = (toastMessage:ToastMessage) => {
    switch (toastMessage.category) {
      case "error":
        return "danger";
      default:
        return toastMessage.category;
    }
  }

  useEffect(() => {
    ToastMessagesService.registerAddMessageFn(addMessage);
  }, [addMessage]);

  return (
    <ToastMessagesContext.Provider value={{ toastMessages, addMessage , removeToastMessage }}>
      {children}
      <ToastContainer position="bottom-end" className="p-3">
        {toastMessages.map((toastMessage) => (
          <Toast
            key={toastMessage.id}
            bg={getBgColor(toastMessage)}
            autohide delay={toastMessage.duration ?? 10000}
            onClose={() => removeToastMessage(toastMessage.id)}
          >
            <Toast.Header>
              <strong className="me-auto">{getTitle(toastMessage)}</strong>
            </Toast.Header>
            <Toast.Body className='text-white'>
              <div style={{whiteSpace: 'pre-line'}}>{toastMessage.message}</div>
            </Toast.Body>
          </Toast>
        ))}
      </ToastContainer>
    </ToastMessagesContext.Provider>
  );
};

export const useToastMessages = (): MessageContextType => {
  const context = useContext(ToastMessagesContext);
  if (context === undefined) {
    throw new Error("useToastMessages must be used within an ToastMessagesProvider");
  }
  return context;
};
